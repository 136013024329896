<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="CloseModal()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="12">
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">Segment</label>
              <div class="col-md-9">
                <v-select
                  ref="SegmentSelectRef"
                  v-model="SegmentSelect"
                  :value.sync="SegmentSelect"
                  :options="SegmentItem"
                  placeholder="-- Select --"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CInput
              ref="SubSegmentNameRef"
              label="SubSegment name"
              horizontal
              v-model="SubSegmentData.SubSegmentName"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">Status</label>
              <div class="col-md-9">
                <CSwitch
                  style="padding-top: 5px"
                  color="info"
                  shape="pill"
                  labelOn="on"
                  labelOff="off"
                  :checked.sync="SubSegmentData.isActive"
                />
              </div>
            </div>
            <!-- <div class="col-md-3">
              <label class="">Status</label>
            </div> -->
            <!-- <div class="col-md-9 pr-0">
              
            </div> -->
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton
          @click="SaveData('Save')"
          v-if="viewmode !== 'view'"
          color="primary"
        >
          Save
        </CButton>
        <CButton @click="CloseModal()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
    <!-- Modal  end-->
  </div>
</template>
<style>
.c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #62e19c;
  border-color: #3cbd76;
}
.c-switch-info .c-switch-input:checked + .c-switch-slider::before {
  border-color: #3cbd76;
}
</style>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";
import axios from "axios";

var URL_Insert = `${store.getters.URL}/api/MasterSubSegment/insert`;
var URL_Update = `${store.getters.URL}/api/MasterSubSegment/update`;
var URL_GetSegment = `${store.getters.URL}/api/MasterSegment/getall`;
const titleAdd ="Add SubSegment";
const titleEdit ="Edit SubSegment";

export default {
  name: "AddOrUpdateSubSegmentModal",
  components: {
    vSelect,
  },
  data() {
    return {
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      titleModal: titleAdd,
      SegmentItem: [{ value: 0, label: "-- Select --" }],
      SegmentSelect: { value: 0, label: "-- Select --" },
      SubSegmentData:{
        SegmentId: 0,
        SubSegmentId: 0,
        SubSegmentName: null,
        isActive: true,
      },
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    viewmode: {
      type: String,
      default: "view",
      required: false
    }
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("AddOrUpdateSubSegmentModal-updated", v);
      }
    },
  },
  mounted() {
    this.GetSegmentItem();
  },
  methods: {
    setDefault() {
      this.SubSegmentData.SegmentId = 0;
      this.SubSegmentData.SubSegmentName = null;
      this.SubSegmentData.SubSegmentId = 0;
      this.SubSegmentData.isActive = true;
      this.SegmentSelect = { value: 0, label: "-- Select --" };

      this.$refs.SubSegmentNameRef.$el.children[1].children[0].style.borderColor = "";
      this.$refs.SegmentSelectRef.$el.children[0].style.borderColor = "";
    },
    CloseModal() {
      this.isshow = false;
      this.setDefault();
    },
    validateFiled() {
      let a = true;
      let b = true;
      if(this.SubSegmentData.SubSegmentName == null|| this.SubSegmentData.SubSegmentName == "") {
        this.$refs.SubSegmentNameRef.$el.children[1].children[0].style.borderColor = "red";
        a = false;
      } else {
        this.$refs.SubSegmentNameRef.$el.children[1].children[0].style.borderColor = "";
      }

      if(this.SegmentSelect?.value == 0 ) {
        this.$refs.SegmentSelectRef.$el.children[0].style.borderColor = "red";
        b = false;
      } else if (this.SegmentSelect == null) {
        this.$refs.SegmentSelectRef.$el.children[0].style.borderColor = "red";
        b = false;
      } else {
        this.$refs.SegmentSelectRef.$el.children[0].style.borderColor = "";
      }
      if(!a || !b)
      {
        this.$_toast_Noti('error','error', 'Please enter data require.');
      }
      return (a == true && b == true);
    },
    SaveData(action) {
      let reVal = false;
      //console.log("this.SubSegmentData", this.SubSegmentData);
      reVal =this.validateFiled();
      if(reVal) {
        if (this.SubSegmentData.SubSegmentId > 0) {
          this.UpdateData();
        } else {
          this.AddData();
        }
      }
      //console.log("SS", this.SegmentSelect);
    },
    GetSegmentItem(){
      axios.get(URL_GetSegment)
      .then(res => {
        let options = [{ value: 0, label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.SegmentID,
              label: data.SegmentDesc,
            });
          });
          this.SegmentItem = options;
        }
      })
      .catch(err => {
        console.error("err",err);
      });
    },
    AddData() {
      axios.post(URL_Insert, this.mapDTO(this.SubSegmentData))
      .then(res => {
        if (res.status === 200) {
          this.$_toast_Noti("success", "success", "");
          this.CloseModal();
          this.$emit("save-subsegment-success");
        }
      })
      .catch(err => {
        console.error("err insret subsegment",err);
      });
    },
    UpdateData() {
      axios.put(URL_Update, this.mapDTO(this.SubSegmentData))
      .then(res => {
        if (res.status === 200) {
          this.$_toast_Noti("success", "success", "");
          this.CloseModal();
          this.$emit("save-subsegment-success");
        }
      })
      .catch(err => {
        console.error("err update subsegment",err);
      });
    },
    mapDTO(item) {
      let param = {
        SubSegmentID: 0,
        SubSegmentDesc: null,
        SegmentID: 0,
        IsActive: true
      };

      param.SubSegmentID = item.SubSegmentId;
      param.SubSegmentDesc = item.SubSegmentName;
      param.SegmentID = this.SegmentSelect.value;
      param.IsActive = item.isActive;
      //console.log("item", item);
      //console.log("param", param);
      return param;
    },

    bindDataToDdls(id){
      let result = null;
      result = this.SegmentItem.filter(e => e.value == id);
      if (result != null) {
        this.SegmentSelect = result[0];
      }
    },
  },
  watch: {
    showModal: function (newVal, oldVal) {
      // console.log("showModal", newVal);
      // console.log("item", this.item);
      if (newVal) {
        let temp = this.item;
        if (temp != null) {
        this.titleModal = titleEdit;
        this.SubSegmentData.SubSegmentId = temp.SubSegmentID;
        this.SubSegmentData.SubSegmentName = temp.SubSegmentDesc;
        this.bindDataToDdls(temp.SegmentID);
        this.SubSegmentData.isActive = temp.IsActive;
      } else {
        this.titleModal = titleAdd;
        this.setDefault();
      }
    }
  },
  }
};
</script>
